import { baseRequest } from '@/API'
//资源分类，数据领域
const DataField = () => {
    return baseRequest({
        url: '/catalog/dataDomain/selectName',
        method: 'POST'
    })
}
const ResourceClassification = () => {
    return baseRequest({
        url: '/catalog/resClass/selectName',
        method: 'POST'
    })
}

// 获取数据目录数据机构
export function GetDataCatalogOrg(params) {
    return baseRequest({
        url: '/catalog/dataOpen/selectCatalogDepts',
        method: 'POST',
        params
    })
}
// 获取数据目录数据领域
export function GetDataCatalogDataDomain(params) {
    return baseRequest({
        url: '/catalog/dataOpen/selectDataDomains',
        method: 'POST',
        params
    })
}
// 全局搜索历史
export function searchLog(JSON) {
    return baseRequest({
        url: '/usercenter/search/openPlatformSearchLog',
        method: 'POST',
        data: JSON
    })
}
// 资源提供方机构树查询
// const OrgTree = () => {
//     return baseRequest({
//         url: '/composite/org/search',
//         method: 'POST',
//         data: { districtCode: '330900', orgCode: '' }
//     })
// }

export default {
    async getData({ commit, state }) {
        await ResourceClassification().then(res => {
            commit('setResClassData', { resClassOptions: res })
        })
        await DataField().then(res => {
            commit('setDataDomain', { dataDomainOptions: res })
        })
        // await OrgTree().then(res => {
        //     commit('setOrgData', { orgOptions: res })
        // })
    },
    async getDataDomain({ commit, state }) {
        if (state.dataDomainOptions.length) {
            commit('setDataDomain', { dataDomainOptions: state.dataDomainOptions })
            return
        }
        await DataField().then(res => {
            commit('setDataDomain', { dataDomainOptions: res })
        })
    },
    getDataCatalogTypeList({ commit, state }, params) {
        if (state.catalogDeptsList.length && state.catalogDomainList.length) {
            commit('setCatalogDeptsList', state.catalogDeptsList)
            commit('setCatalogDomainList', state.catalogDomainList)
            return
        }
        Promise.allSettled([GetDataCatalogOrg(params), GetDataCatalogDataDomain(params)]).then(res => {
            commit('setCatalogDeptsList', res[0].status == 'rejected' ? [] : res[0].value)
            commit('setCatalogDomainList', res[1].status == 'rejected' ? [] : res[1].value)
        })
    },
    searchHistory({ commit, state }) {
        searchLog({
            pageNo: 1,
            pageSize: 5,
            param: {}
        }).then(res => {
            if (Array.isArray(res.records) && res.records.length) {
                const data = res.records
                let historyData = []
                data.forEach(item => {
                    historyData.push(item.content)
                })
                commit('setSearchHistoryList', historyData)

                return
            }
            commit('setSearchHistoryList', state.searchHistoryList)
        })
    }
}
