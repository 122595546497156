import { baseRequest } from '@/API'

//fuwu列表
export function SearchLeft(JSON) {
    return baseRequest({
        url: '/usercenter/resourceSearch/sourceMarketSearch',
        method: 'POST',
        data: JSON
    })
}
//服务列表
export function Filter(JSON) {
    return baseRequest({
        url: '/usercenter/resourceSearch/sourceMarketSearchByPage',
        method: 'POST',
        data: JSON
    })
}
//新闻列表
export function getNews(JSON) {
    return baseRequest({
        url: '/feedback/opennews/publishnews',
        method: 'POST',
        data: JSON
    })
}
//政策法规
export function getRules(JSON) {
    return baseRequest({
        url: '/feedback/opennews/publishpolicy',
        method: 'POST',
        data: JSON
    })
}
//获取数据目录数据机构 数据分类
export function getDataDomains(query) {
    return baseRequest({
        url: '/catalog/dataOpen/selectDataDomains',
        method: 'POST',
        // data: JSON
        params: query,
        data: query
    })
}
//获取数据目录数据领域  数据分类
export function getDepartment(JSON) {
    return baseRequest({
        url: '/catalog/dataOpen/selectCatalogDepts',
        method: 'POST',
        data: JSON
    })
}
//最新数据
export function getNewData(JSON) {
    return baseRequest({
        url: '/catalog/dataOpen/newData',
        method: 'POST',
        data: JSON
    })
}
//最新数据/热门数据：数据领域
export function dataDomainselectByIdName(query) {
    return baseRequest({
        url: '/catalog/dataDomain/selectByIdName',
        method: 'POST',
        params: query
    })
}

// 全局搜索历史
export function searchLog(JSON) {
    return baseRequest({
        url: '/usercenter/search/openPlatformSearchLog',
        method: 'POST',
        data: JSON
    })
}
// 清空历史记录
export function clearHisrory(JSON) {
    return baseRequest({
        url: '/usercenter/search/cleanOpenSearchLog',
        method: 'POST',
        data: JSON
    })
}

// 左侧列表
export function searchMenu(JSON) {
    return baseRequest({
        url: '/usercenter/search/openPlatformSearch',
        method: 'POST',
        data: JSON
    })
}

// 全局搜索分页
export function searchList(JSON) {
    return baseRequest({
        url: '/usercenter/search/openPlatformSearchByPage',
        method: 'POST',
        data: JSON
    })
}
// 获取签名
export function getSign(params) {
    return baseRequest({
        url: '/interface/resourceservice/simulation/sign',
        method: 'post',
        data: params
    })
}
// 发送测试请求
export function getInterfaceTestResult(params) {
    return baseRequest({
        url: '/interface/resourceservice/simulation/query',
        method: 'post',
        data: params
    })
}
