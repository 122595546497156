let mutations = {
    //设置路由权限表
    setIndex(state, val) {
        state.isIndex = val
    },
    //设置是否个人中心页面
    setUserCenter(state, val) {
        state.isUserCenter = val
    },
    setResClassData: (state, payload) => {
        state.resClassOptions = payload.resClassOptions
    },
    setDataDomain: (state, payload) => {
        state.dataDomainOptions = payload.dataDomainOptions
    },
    // setOrgData: (state, payload) => {
    //     state.orgOptions = payload.orgOptions
    // },
    setCatalogDeptsList: (state, val) => {
        state.catalogDeptsList = val
    },
    setCatalogDomainList: (state, val) => {
        state.catalogDomainList = val
    },
    setSearchHistoryList: (state, val) => {
        state.searchHistoryList = val
    },
    setToken: (state, payload) => {
        state.kf_token = payload
    },
    setUSER: (state, payload) => {
        state.KF_USER = payload
    },
    updateAreaCode:(state, payload) => {
        state.areaCode = payload || null
    },
}
export default mutations
