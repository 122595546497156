import { getCookie } from '@/utils/cookie'
import { message } from 'ant-design-vue'
const axios = require('axios')
axios.default.withCredentials = true

let ulrbase = ''
const urlAPI = window.globalData.BaseUrl
if (process.env.NODE_ENV === 'development') {
    // ulrbase = '/api'
} else {
    ulrbase = urlAPI
}

/* loginRequest 请求结束开始 */
let loginRequest = axios.create({
    baseURL: ulrbase,
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    validateStatus(status) {
        switch (status) {
            case 400:
                message.error('请求出错')
                break
            case 401:
                // message.error('账号或密码错误')
                return
            case 403:
                message.warning('拒绝访问')
                break
            case 404:
                message.warning('请求错误,未找到该用户')
                break
            case 500:
                message.warning('服务端错误，请稍候重试')
                break
            case 503:
                message.warning('服务端错误，请稍候重试')
                break
        }
        return status >= 200 && status < 300
    }
})
loginRequest.interceptors.request.use(
    config => {
        if (getCookie('kf_token')) {
            config.headers.common['X-Subject-Token'] = getCookie('kf_token') || this.$store.state.kf_token
        }
        return config
    },
    err => {
        return Promise.reject(err)
    }
)

loginRequest.interceptors.response.use(
    response => {
        if (response.status != '200') {
            console.log('login:', response)
            // message.error(response.data.msg || '未知错误')
        } else {
            return response.data
        }
    },
    error => {
        let { code } = error.response.data
        switch (code) {
            case 101502:
                message.error(error.response.data.message)
                break
            case 101504:
                message.error(error.response.data.message)
                break
            case 451:
                message.error(error.response.data.message || '服务端错误')
                break
            case 500:
                message.error(error.response.data.message || '服务端错误')
                break
        }
        return Promise.reject(error.response)
    }
)
export { loginRequest }
