import { baseRequest } from '@/API'

//互动交流
export function getCommunication() {
    return baseRequest({
        url: '/catalog/other/config/zj/talk/url',
        method: 'GET'
    })
}

// 互动交流反馈接口-数据需求列表
export function getDataDemandList(data){
    return baseRequest({
        url: '/feedback/communication/dataDemand/list',
        method: 'POST',
        data
    })
}

// 互动交流反馈接口-数据需求提交
export function addDataDemand(data){
    return baseRequest({
        url: '/feedback/communication/dataDemand/submit',
        method: 'POST',
        data
    })
}

// 互动交流反馈接口-数据纠错列表
export function getErrorCorrectionList(data){
    return baseRequest({
        url: '/feedback/communication/errorCorrection/list',
        method: 'POST',
        data
    })
}

// 互动交流反馈接口-数据纠错提交
export function addErrorCorrection(data){
    return baseRequest({
        url: '/feedback/communication/errorCorrection/submit',
        method: 'POST',
        data
    })
}

// 互动交流反馈接口-平台体验建议列表
export function getPlatformExperienceList(data){
    return baseRequest({
        url: '/feedback/communication/platformExperience/list',
        method: 'POST',
        data
    })
}

// 互动交流反馈接口-平台体验建议提交
export function addPlatformExperience(data){
    return baseRequest({
        url: '/feedback/communication/platformExperience/submit',
        method: 'POST',
        data
    })
}

// 互动交流反馈接口-所属行业列表
export function getTradesList(){
    return baseRequest({
        url: '/feedback/communication/dataDemand/tradesList',
        method: 'GET'
    })
}

// 获取数源部门清单
export function getDeptList(){
    return baseRequest({
        url: '/catalog/dataOpen/getDeptList',
        method: 'GET'
    })
}