// import { message as Message } from 'ant-design-vue'
// import throttle from '../utils/debounce'

const setCookie = function(name, value, expiredays) {
    let exdate = new Date()
    exdate.setDate(exdate.getDate() + expiredays)
    document.cookie = name + '=' + value + (expiredays == null ? '' : ';expires=' + exdate.toGMTString())
}
const getCookie = function(name) {
    let nameData = name + '='
    let allcookies = document.cookie.split(';')
    for (let i = 0; i < allcookies.length; i++) {
        let c = allcookies[i].trim()
        if (c.indexOf(nameData) == 0) {
            return c.substring(nameData.length, c.length)
        }
    }
    return ''
}
const delCookie = function(name) {
    var exp = new Date()
    exp.setTime(exp.getTime() - 1)
    // throttle(() => {
    // Message.error('信息过期，请重新登录！')
    // }, 200)
    var cval = getCookie(name)

    if (cval !== null) {
        document.cookie = name + '=;expires=' + exp.toGMTString()
    }
}
export { setCookie, getCookie, delCookie }
