<template>
    <div class="page">
        <div class="main">
            <div class="logo">
                <img src="../../assets/index/logo.png" class="logoIMG" alt="" />
                <div>
                    <p class="title1">{{city.name}}·数据开放</p>
                    <p class="title2">数据开放门户</p>
                </div>
            </div>
            <div class="content">
                <div class="loginHead">欢迎访问 数据开放门户</div>
                <div class="loginTip">登录·LOG IN</div>
                <a-form-model ref="loginForm" class="form1" :model="loginForm" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
                    <div class="iptItem" :class="{ iptfoc: isActive == 1 }">
                        <div><a-icon slot="prefix" type="user" :style="iconStyle" /></div>
                        <div>
                            <a-form-model-item prop="username"><a-input placeholder="用户名" v-model.trim="loginForm.username" @focus="handleChange(1)" @blur="handleChange"></a-input></a-form-model-item>
                        </div>
                    </div>
                    <div class="iptItem" :class="{ iptfoc: isActive == 2 }">
                        <div><a-icon slot="prefix" type="lock" :style="iconStyle" /></div>
                        <div>
                            <a-form-model-item prop="password"><a-input type="password" placeholder="密码" v-model="loginForm.password" @focus="handleChange(2)" @blur="handleChange"></a-input></a-form-model-item>
                        </div>
                    </div>
                </a-form-model>
                <a-button @click="onSubmit" class="btn" type="primary" html-type="submit">
                    登录
                </a-button>
                <div class="comp">浙江大华技术股份有限公司</div>
            </div>
        </div>
    </div>
</template>

<script>
import { Login, LoginInfo } from './api/index'
import { setCookie, getCookie } from '@/utils/cookie'
import md5 from 'js-md5'
export default {
    name: 'login',
    data() {
        return {
            iconStyle: { color: '#969fa8', fontSize: '16px' },
            form: this.$form.createForm(this, { name: 'loginForm' }),
            isActive: -1,
            loginForm: {
                username: '',
                password: ''
            },
            rules: {
                username: [
                    { required: true, message: '请输入用户名', trigger: 'blur' }
                    // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, message: '密码至少6位', trigger: 'blur' }
                ]
            },
            encrypt: '',
            username: ''
        }
    },
    components: {},
    created() {
        if (getCookie('kf_token') && getCookie('KF_USER')) {
            this.$router.push('/')
        }
        let userData
        try {
            if (getCookie('KF_USER')) {
                userData = JSON.parse(getCookie('KF_USER'))
            }
        } catch (error) {
            userData = null
        }
        if (userData) {
            this.loginForm = {
                username: userData.username,
                password: userData.password
            }
        } else {
            this.loginForm = {
                username: '',
                password: ''
            }
        }
    },
    mounted() {
        window.onkeydown = theEvent => {
            let keyCode = theEvent.keyCode || theEvent.which || theEvent.charCode
            if (Number(keyCode) === 13) {
                this.onSubmit()
            }
        }
    },
    methods: {
        handleChange(index) {
            this.isActive = index ? index : -1
        },
        onSubmit() {
            this.$refs.loginForm.validate(valid => {
                if (valid) {
                    this.loginFist()
                } else {
                    return false
                }
            })
        },
        loginFist() {
            Login({ userName: this.loginForm.username, clientType: 'web' })
                .then(res => {
                    this.encrypt = res
                    if (this.encrypt) {
                        var password = md5(this.loginForm.password)
                        //获取签名
                        var md5Salt = 'TheNextService'
                        var tem1 = this.loginForm.username + ':' + md5Salt + ':' + password
                        var tem2 = md5(tem1)
                        var temN = md5(this.loginForm.username + ':' + md5Salt + ':' + tem2)
                        var tem3 = temN + ':' + this.encrypt.randomKey
                        // var tem3 = tem2 + ':' + this.encrypt.randomKey
                        var signature = md5(tem3)
                        var loginBean = {
                            clientType: 'web', //客户端类型
                            encryptType: this.encrypt.encryptType, // 加密类型
                            password: password,
                            randomKey: this.encrypt.randomKey,
                            realm: this.encrypt.realm, // 盐值
                            signature: signature, // 签名
                            userName: this.loginForm.username // 用户名
                        }
                        this.login2(loginBean)
                    }
                })
                .catch(() => {
                    this.loginForm.password = ''
                })
        },
        login2(loginData) {
            Login(loginData)
                .then(res => {
                    this.loginUserInfo(res)
                })
                .catch(() => {
                    this.loginForm.password = ''
                })
        },
        async loginUserInfo(res) {
            await setCookie('kf_token', res.token)
            this.$store.commit('setToken', res.token)
            this.$nextTick(() => {
                LoginInfo({ id: res.userCode }).then(res => {
                    let user = res.data
                    let KF_USER = JSON.stringify(user)
                    setCookie('KF_USER', KF_USER)
                    this.$store.commit('setUSER', KF_USER)
                    this.$router.push({
                        path: 'Home'
                    })
                })
            })
        }
    }
}
</script>

<style scoped lang="scss">
.page {
    height: 100vh;
    background-image: url(../../assets/index/indexBG.png);
    background-repeat: no-repeat;
    background-size: cover;
    font-family: Source Han Sans CN;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #ffffff;
    .main {
        display: block;
        max-width: 1660px;
        min-width: 664px;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        // position: relative;
        display: flex;
        justify-content: flex-end;
        .logo {
            width: 206px;
            height: 37px;
            position: absolute;
            top: 30px;
            left: 160px;
            display: flex;
            justify-content: space-between;
            z-index: 9;
            .logoIMG {
                width: 40px;
                height: 37px;
            }
            .title1 {
                opacity: 0.8;
            }
            .title2 {
                font-size: 20px;
                line-height: 20px;
            }
        }
        .content {
            min-width: 674px;
            height: 100%;
            border: solid 2px #646d75;
            background-color: rgba(27, 29, 41, 0.5);
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            .loginHead {
                height: 30px;
                font-size: 30px;
                line-height: 30px;
                // position: absolute;
                // top: 123px;
                // left: 185px;
                margin-top: 123px;
            }
            .loginTip {
                height: 21px;
                font-size: 21px;
                line-height: 21px;
                opacity: 0.7;
                // position: absolute;
                // left: 280px;
                // top: 235px;
                margin-top: 82px;
            }
            .form1 {
                width: 472px;
                height: 245px;
                // position: absolute;
                // left: 102px;
                // top: 286px;
                margin-top: 30px;
                .iptItem {
                    width: 472px;
                    height: 63px;
                    background-color: rgba(81, 94, 113, 0.5);
                    border-radius: 4px;
                    border: solid 2px #717f94;
                    display: flex;
                    box-sizing: content-box;
                    margin-bottom: 26px;
                    ::v-deep input {
                        width: 400px;
                        height: 67px;
                        background-color: rgba(81, 94, 113, 0.5);
                        border-radius: 4px;
                        font-size: 16px;
                        line-height: 63px;
                        color: #969fa8;
                        border: none;
                    }
                    ::v-deep input:focus {
                        color: #ffffff;
                        box-shadow: none;
                    }
                }
                .iptItem > div:nth-child(1) {
                    width: 72px;
                    height: 63px;
                    border-right: 1px solid #717f94;
                    text-align: center;
                    line-height: 63px;
                }
                .iptfoc {
                    border: 2px solid #0184ff;
                }
            }
            .btn {
                width: 200px;
                height: 68px;
                margin-top: 36px;
                font-size: 20px !important;
                line-height: 68px;
            }
            .comp {
                height: 15px;
                font-size: 15px;
                line-height: 15px;
                position: absolute;
                left: 247px;
                bottom: 48px;
            }
        }
    }
}
</style>
