<template>
    <div class="NavHeader indexHeader" :class="{ indexOnly: isIndex }">
        <div class="main">
            <div class="logo">
                <h1>{{ city.mapName }}·公共数据开放平台</h1>
                <span>{{ city.pinyin }} · DATA OPENING</span>
            </div>
            <div class="search">
                <search-bar class="searchBox" :isLogin="isLogin"></search-bar>
            </div>
            <div class="actions" v-if="isLogin">
                <span @click="goUserCenter" style=" margin-right: 30px;" :class="{ isActive: isUserCenter }">欢迎您，{{ username }}</span>
                <div @click="goUserCenter" class="user-img"><img src="@/assets/index/user.png" alt="" /></div>
                <span style="margin-left:10px" @click="quit">退出</span>
            </div>
            <div class="actions" v-else>
                <span @click="goUserCenter" style=" margin-right: 30px;" :class="{ isActive: isUserCenter }">请登录</span>
                <div @click="goUserCenter" class="user-img"><img src="@/assets/index/user.png" alt="" /></div>
            </div>
            <div class="header-title" @click="goHome">
                <div class="header-title-logo">
                    <img src="@/assets/logo_gh.png" />
                    {{ city.mapName }}人民政府
                </div>
            </div>
            <div class="header-location">
                <div class="header-location-wrap" @mouseover="onMouseoverLocation" @mouseout="onMouseoutLocation">
                    <span class="location-name">{{ activeLocation.areaName }}</span>
                    <div class="header-location-list" v-show="locationListVisible">
                        <section class="header-location-item" v-for="(item,index) in locationList" :key="index" @click.stop="onClickLocation(item)">{{item.areaName}}</section>
                    </div>
                </div>
            </div>
            <div class="total-container">
                <section class="total-wrap">
                    {{activeLocation.areaCode?activeLocation.areaName:'全市'}}共开放
                    <span class="y">{{ dataOpenCounts.dataCatalogApiCount }}</span>
                    个API接口，
                    <span class="y">{{ dataOpenCounts.dataCatalogCollectionCount }}</span>
                    个数据集，
                    <span class="y">{{ dataOpenCounts.dataSetItemCount }}</span>
                    个无条件开放数据项，
                    <span class="y">{{ dataOpenCounts.dataCatalogDataCount }}</span>
                    条数据，
                    <span class="y">{{ dataOpenCounts.dataCatalogCount }}</span>
                    条目录，
                    <span class="y">{{ dataOpenCounts.dataCatalogItemCount }}</span>
                    个数据项。
                </section>
            </div>
        </div>
        <div class="menu">
            <div class="center-content">
                <ul class="nav">
                    <li :class="active == child.key ? 'active' : ''" class="parent" v-for="child in routes" :key="child.key" @click="navTo(child.key)">
                        <span>{{ child.name }}</span>
                        <ul class="nav-child">
                            <li :class="childActive == subchild.key ? 'active' : ''" class="child" v-for="subchild in child.children" :key="subchild.key" @click.stop="navTo(subchild.key)">
                                <span>{{ subchild.name }}</span>
                                <ul class="nav-child"></ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <go-login :openModal="openModal" @changeOpenModal="changeOpenModal"></go-login>
        <login-modal v-if="showLoginModal" @cancel="showLoginModal = false"></login-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { getOpenDataCounts, getAllArea } from '@/views/OpenData/api'
import { delCookie, getCookie } from '@/utils/cookie'
import searchBar from '@/views/Home/component/searchBar'
import goLogin from '@/components/GoLogin'
import LoginModal from '@/components/LoginModal'
import { getCommunication } from '../views/InteractiveCommunication/api'
export default {
    // inject: ['reload'],
    name: 'NavHeader',
    data() {
        return {
            showLoginModal: false,
            username: '游客',
            isLogin: false,
            current: ['DataCatalogList'],
            activeIndex: '1',
            activeIndex2: '1',
            routes: [
                {
                    name: '首页',
                    path: 'Home',
                    key: 'Home',
                    children: []
                },
                {
                    name: '开放数据',
                    path: '/OpenData',
                    key: 'OpenData',
                    children: [
                        {
                            name: '数据集',
                            path: 'DataSet',
                            key: 'DataSet'
                        },
                        {
                            name: '数据目录',
                            path: 'DataCatalog',
                            key: 'DataCatalog'
                        }
                    ]
                },
                {
                    name: '接口服务',
                    path: '/InterfaceService',
                    key: 'InterfaceService'
                },
                {
                    name: '应用成果',
                    path: 'ApplicationResults',
                    key: 'ApplicationResults'
                },
                {
                    name: '地图服务',
                    path: 'MapService',
                    key: 'MapService'
                },
                {
                    name: '开发者中心',
                    path: 'DeveloperCenter',
                    key: 'DeveloperCenter'
                },
                {
                    name: '开放指数',
                    path: 'OpenIndex',
                    key: 'OpenIndex'
                },
                {
                    name: '互动交流',
                    path: 'InteractiveCommunication',
                    key: 'InteractiveCommunication'
                },
                {
                    name: '政策动态',
                    path: 'PolicyTrends',
                    key: 'PolicyTrends'
                }
            ],
            active: 'Home',
            childActive: 'DataSet',
            openModal: false,
            dataOpenCounts: {},
            activeLocation:{
                areaCode:'',
                areaName:'区、县（市）站点'
            },
            locationList:[],
            locationListVisible:false
        }
    },
    computed: {
        ...mapState(['isIndex', 'isUserCenter']),
        hasLoginToken() {
            return this.$store.state.kf_token
        }
    },
    components: {
        searchBar,
        goLogin,
        LoginModal
    },
    methods: {
        onMouseoverLocation(){
            this.locationListVisible = true
        },
        onMouseoutLocation(){
            this.locationListVisible = false
        },
        onClickLocation(row){
            let {areaCode, areaName } = row
            this.locationListVisible = false
            if(!areaCode){
                areaName = '区、县（市）站点'
            }
            this.activeLocation = {
                areaCode,
                areaName
            }
            this.$store.commit('updateAreaCode',this.activeLocation.areaCode)
        },
        goHome() {
            this.$router.push({ path: '/Home' })
        },
        getOpenDataCounts() {
            let params = {
                areaCode:this.activeLocation.areaCode || null
            }
            getOpenDataCounts(params).then(res => {
                this.dataOpenCounts = res
                // debugger
            })
        },
        async navTo(name) {
            // 地图
            let _this = this
            if (name == 'MapService') {
                window.open(`https://ditu.amap.com/search?query=${this.city.mapName}`, '_blank')
                return
            }
            // if (name == 'OpenIndex') {
            //     const { href } = _this.$router.resolve({ name })
            //     window.open(href, '_blank')
            //     return
            // }
            let routerNow = this.$route.name
            if (routerNow == name) {
                return false
            }
            this.$router.push({ name })

            // 沟通交流
        },

        goUserCenter() {
            if (this.isLogin) {
                this.$router.push({ name: 'UserCenter' })
                return
            }
            // this.$router.push({ name: 'Login' })
            this.showLoginModal = true
        },
        quit() {
            // const kf_token = getCookie('kf_token')
            delCookie('KF_USER')
            delCookie('kf_token')
            this.$store.commit('setToken', '')
            this.$store.commit('setUSER', '')
            if (getCookie('userType') == 1) {
                window.location.href = window.globalData.thirdPartyPersonalLogoutUrl
            } else {
                window.location.href = window.globalData.thirdPartyCompanyLogoutUrl
            }

            // if (this.$route.path != '/Home/Default/Index') {
            //     this.$router.replace('/')
            // } else {
            //     this.$router.go(0)
            // }
            // Quit({ token: kf_token }).then(() => {
            //     delCookie('KF_USER')
            //     delCookie('kf_token')
            //     this.$store.commit('setToken', '')
            //     this.$store.commit('setUSER', '')
            //     if (this.$route.path != '/Home/Default/Index') {
            //         this.$router.replace('/Home')
            //     } else {
            //         this.$router.go(0)
            //     }
            // })
        },
        changeOpenModal(val) {
            this.openModal = val
        },
        getAllArea(){
            getAllArea().then(res=>{
                this.locationList = res
            })
        },
    },
    watch: {
        $route() {
            this.active = this.$route.matched[0].name
            this.childActive = this.$route.matched[1] ? this.$route.matched[1].name : ''
        },
        hasLoginToken(newVal, oldVal) {
            if (newVal != oldVal) {
                this.isLogin = false
            }
        },
        activeLocation(){
            this.getOpenDataCounts()
        }
    },
    mounted() {
        this.getAllArea()
        this.getOpenDataCounts()
        if (getCookie('KF_USER') && getCookie('kf_token')) {
            this.isLogin = true
            this.username = JSON.parse(getCookie('KF_USER')).userName
        }
    }
}
</script>

<style scoped lang="scss">
.NavHeader {
    width: 100%;
    height: 260px;
    background: #2c3e50;
    position: relative;
    .main {
        display: block;
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        position: relative;
        .header-title {
            position: absolute;
            left: 0px;
            top: 16px;
            .header-title-logo {
                font-size: 24px;
                color: #fff;
                display: flex;
                align-items: center;
                cursor: pointer;
                img {
                    width: 32px;
                    height: 32px;
                    margin-right: 4px;
                }
            }
        }
        .header-location{
            position: absolute;
            left: 220px;
            top: 23px;
            /* padding: 0 20px 0 40px; */
            min-width: 80px;
            height: 24px;
            line-height: 24px;
            border-radius: 12px;
            color: #333;
            font-size: 12px;
            background: #EFF1F3 url('~@/assets/subsite.png') 9px 7px no-repeat;
            
            .header-location-wrap{
                position: relative;
                padding: 0 10px 0 27px;
                .header-location-list{
                    z-index: 6666;
                    display: flex;
                    position: absolute;
                    left: 0;
                    width: 368px;
                    padding: 10px;
                    background: #fff;
                    box-shadow: 0 1px 13px 0 rgba(0,0,0,.3);
                    border-radius: 4px;
                    transition: all .2s ease;
                    // display: none;
                    .header-location-item{
                        margin-right: 24px;
                        cursor: pointer;
                        // margin-bottom: 10px;
                    }
                }
            }
            .location-name{
                cursor: pointer;
            }
        }
        .total-container {
            position: absolute;
            right: 0;
            top: 30%;
            .total-wrap {
                width: 306px;
                box-sizing: border-box;
                padding: 10px;
                background-color: rgba(0, 0, 0, 0.3);
                border-radius: 4px;
                color: #fff;
                font-size: 14px;
                .y {
                    color: #ffda47;
                }
            }
        }
        .logo {
            position: absolute;
            left: 50%;
            top: 48px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            transform: translateX(-50%);
            h1 {
                font-family: FZLTCHJW--GB1-0;
                font-size: 36px;
                line-height: 48px;
                letter-spacing: 0px;
                color: #ffffff;
            }
            span {
                height: 12px;
                font-family: SourceHanSansCN-Regular;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0px;
                color: #ffffff;
            }
        }
        .search {
            width: 400px;
            height: 40px;
            position: absolute;
            left: 50%;
            margin-left: -200px;
            top: 128px;
            background: #fff;
            border-radius: 20px;
            padding-left: 20px;
            z-index: 999;
            input {
                width: 300px;
                padding: 0;
                border: none;
            }
            input:focus {
                border: none;
                box-shadow: none;
            }
            input:disabled {
                color: rgba(0, 0, 0, 0.65);
                background: #fff;
                cursor: text;
            }
        }
        .actions {
            width: 300px;
            position: absolute;
            top: 16px;
            right: 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .isActive {
                color: $blue;
            }
            .user-img {
                width: 30px;
                height: 30px;
                img {
                    width: 30px;
                    height: 30px;
                }
            }
            span {
                cursor: pointer;
                font-family: Source Han Sans CN;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                line-height: 27px;
                letter-spacing: 0px;
                color: #ffffff;
                display: inline;
            }
        }
    }
    .menu {
        background: rgba($color: #071427, $alpha: 0.3);
        width: 100%;
        height: 59px;
        position: absolute;
        bottom: 0;
        left: 0;
        .center-content {
            height: 46px;
            position: relative;
            .nav {
                width: 100%;
                display: flex;
                justify-content: center;
                z-index: 10;
                @keyframes hover {
                    from {
                        height: 0;
                    }
                    to {
                        height: 60px;
                    }
                }
                li {
                    height: 60px;
                    width: 125px;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    position: relative;
                    span {
                        z-index: 11;
                    }
                    .nav-child {
                        position: absolute;
                        top: 60px;
                        width: 100%;
                        z-index: 10;
                        display: none;
                        li {
                            height: 48px;
                            background: rgba($color: #000000, $alpha: 0.8);
                        }
                        li:hover {
                            color: #357df1;
                            background-color: rgba($color: #000000, $alpha: 0.9);
                        }
                    }
                }
                li.parent.active {
                    background: #357df1;
                }
                li.child.active {
                    background: rgba($color: #000000, $alpha: 0.9);
                    color: #357df1;
                }
                li.parent::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 0;
                    background: #357df1;
                }
                li.parent:hover {
                    &::before {
                        animation: hover 0.3s;
                        animation-fill-mode: forwards;
                    }
                    .nav-child {
                        display: block;
                    }
                }
            }
        }
    }
}

.indexHeader {
    background: url('~@/assets/index/head.png') top center no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(73, 79, 92, 0.81);
}
.indexOnly {
    z-index: 100;
}
.single {
    el-menu-item:hover {
        background-color: red !important;
    }
}
</style>
