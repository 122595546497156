<template>
    <div></div>
</template>
<script>
import { GetUserInfo } from '@/API/http'
import { setCookie } from '@/utils/cookie'
// 作为中转
export default {
    data() {
        return {}
    },
    beforeRouteEnter: (to, from, next) => {
        next(vm => {
            let jsonObj = JSON.parse(to.query.json)
            if (to.query.json) {
                setCookie('kf_token', jsonObj.login_token)
                setCookie('userCode', jsonObj.uid)
                setCookie('userName', jsonObj.username)
                vm.$store.commit('setToken', jsonObj.login_token)
                vm.getUserInfo(jsonObj.uid)
            } else {
                vm.$message.error('登录失败，请重新登录')
                vm.$router.replace('/') //跳首页
            }
        })
    },
    methods: {
        getUserInfo(userCode) {
            GetUserInfo(userCode)
                .then(res => {
                    let KF_USER = JSON.stringify(res)
                    setCookie('KF_USER', KF_USER)
                    this.$store.commit('setUSER', KF_USER)
                    this.$router.replace('/')
                })
                .catch(() => {
                    this.$message.error('获取用户信息失败')
                    this.$router.replace('/')
                })
        }
    }
}
</script>
