import { baseRequest } from '@/API/index'
import { loginRequest } from '@/API/login'
//requestData数据
export function httpRequest(url, method, param) {
    return baseRequest({
        url: url,
        method: method || 'post',
        data: param || {}
    })
}
//第三方登录
export function thirdPartyLogin(params) {
    return new Promise((resolve, reject) => {
        loginRequest({
            // baseURL: 'http://10.33.9.119:8082/',
            url: '/usercenter/thirdParty/authorize',
            method: 'get',
            params
        })
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
//文件上传
export function uploadFile(params) {
    return baseRequest({
        url: '/usercenter/upload/file',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
//字典数据
export function GetDictionary(name) {
    let prms = new Promise((resolve, reject) => {
        baseRequest({
            url: '/catalog/sysdict/select',
            method: 'post',
            params: { type: name }
        })
            .then(res => {
                let data = res
                let arr = []
                for (let item of data) {
                    arr.push({
                        value: item.value,
                        label: item.label
                    })
                }
                resolve(arr)
            })
            .catch(() => {
                reject()
            })
    })
    return prms
}
//字典数据
export function GetUserInfo(id) {
    let prms = new Promise((resolve, reject) => {
        baseRequest({
            url: '/usercenter/userinfo/detail',
            method: 'post',
            data: { id }
        })
            .then(res => {
                resolve(res)
            })
            .catch(() => {
                reject()
            })
    })
    return prms
}
