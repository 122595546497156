<template>
    <div class="subFramework">
        <keep-alive>
            <router-view v-if="$route.meta.keepalive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepalive"></router-view>
    </div>
</template>

<script>
export default {
    inject: ['reload'],
    name: 'SubFramework',
    data() {
        return {}
    },
    computed: {},
    methods: {},
    mounted() {}
}
</script>

<style scoped lang="scss">
.subFramework {
}
</style>
