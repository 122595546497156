import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import _ from 'lodash'
import '@/utils/polyfill'
Vue.config.productionTip = false
// UI START
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import SameStyleBox from '@c/SameStyleBox/' //内容区标题框
import FloatBox from '@c/FloatBox/index' //左侧浮动区展示标题框
import ApprovalProcess from '@c/ApprovalProcess/index' //审批流程
import mixin from '@/utils/mixin'
import Meta from 'vue-meta'
Vue.use(Meta)
Vue.mixin(mixin)
Vue.use(SameStyleBox)
Vue.use(FloatBox)
Vue.use(ApprovalProcess)
Vue.use(Antd)
import { message } from 'ant-design-vue'
Vue.prototype.$message = message
message.config({
    duration: 2,
    maxCount: 1
})
// UI END
// 全局组件注册

// 全局CSS
import './style/reset.scss'
import './style/elementReset.scss'
import './style/common.scss'
Vue.prototype._ = _
Vue.config.productionTip = false
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
