import { baseRequest } from '@/API'

// 获取数据集列表
export function GetList(params) {
    return baseRequest({
        url: '/catalog/dataOpen/otherPage',
        method: 'post',
        data: params
    })
}
// 获取数据目录列表
export function GetDataCatalogList(params) {
    return baseRequest({
        url: '/catalog/dataOpen/catalogData',
        method: 'post',
        data: params
    })
}
// 获取收藏目录列表
export function GetStarList(JSON) {
    return baseRequest({
        // baseURL: BaseUrl1,
        url: '/usercenter/opencollect/selectcatologid',
        method: 'POST',
        data: JSON
    })
}
// 数据目录收藏提交
export function GiveStar(JSON) {
    return baseRequest({
        url: '/usercenter/opencollect/catlog',
        method: 'POST',
        data: JSON
    })
}
// 删除个人收藏
export function DeleteStar(JSON) {
    return baseRequest({
        url: '/usercenter/opencollect/del',
        method: 'POST',
        params: JSON
    })
}
// 获取数据集数据机构
export function GetOrg(query) {
    return baseRequest({
        url: '/catalog/dataOpen/selectCatalogDepts',
        method: 'POST',
        params: query,
        data: query
    })
}
//受限目录提交
export function submitLimitCatalog(params) {
    return baseRequest({
        url: '/catalog/opencatalogapply/catalogapplysubmit',
        method: 'POST',
        data: params
    })
}

// 获取数据目录数据机构
export function GetDataCatalogOrg(query) {
    return baseRequest({
        // baseURL: BaseUrl1,
        url: '/catalog/dataOpen/selectCatalogDepts',
        method: 'POST',
        params: query,
        data: query
    })
}
// 获取数据集数据领域
export function GetDataDomain(query) {
    return baseRequest({
        url: '/catalog/dataOpen/selectDataDomains',
        method: 'POST',
        params: query,
        data: query
    })
}
// 获取数据目录数据领域
export function GetDataCatalogDataDomain(query) {
    return baseRequest({
        // baseURL: BaseUrl1,
        url: '/catalog/dataOpen/selectDataDomains',
        method: 'POST',
        params: query,
        data: query
    })
}

// 获取数据集详情数据
export function GetDetail(query) {
    return baseRequest({
        // baseURL: BaseUrl1,
        url: '/catalog/dataOpen/selectOne',
        method: 'POST',
        params: query
    })
}

// 获取所有字典项
export function GetDicts(query) {
    return baseRequest({
        // baseURL: BaseUrl1,
        url: '/catalog/sysdict/selectAll',
        method: 'POST',
        params: query
    })
}

//数据领域详情
export function GetDataDomainDetail(query) {
    return baseRequest({
        url: '/catalog/dataDomain/selectByIdName',
        method: 'POST',
        params: query
    })
}

//资源分类详情
export function GetResClassDetail(query) {
    return baseRequest({
        url: '/catalog/resClass/selectByIdName',
        method: 'POST',
        params: query
    })
}
// 数据可视化的表格

export function GetVisualizationTable(query) {
    return baseRequest({
        url: '/catalog/dataOpen/preview',
        method: 'POST',
        params: query
    })
}
// 数据可视化的 echarts 数据
export function GetVisualizationData(JSON) {
    return baseRequest({
        url: '/catalog/dataOpen/visualization',
        method: 'POST',
        data: JSON
    })
}
export function getOpenDataCounts(params) {
    return baseRequest({
        url: '/catalog/dataOpen/count',
        method: 'GET',
        params
    })
}

// 获取所有区划信息
export function getAllArea(){
    return baseRequest({
        url: '/catalog/dataOpen/getAllArea',
        method: 'GET'
    })
}

export function getAppliedCatalogs() {
    return baseRequest({
        url: '/catalog/dataOpen/getAppliedCatalogs',
        method: 'POST'
    })
}

export function getResCatalogApplyDetail(params) {
    return baseRequest({
        url: '/catalog/opencatalogapply/rescatalogapplydetail',
        method: 'POST',
        data: params
    })
}
// 申请状态 /interface/openInterface/getInterfaceApplied

export function GetApplicationState(query) {
    return baseRequest({
        url: '/interface/openInterface/getInterfaceApplied',
        method: 'POST',
        params: query
    })
}
// 下载
export function getLoadTable(query) {
    return baseRequest({
        url: '/catalog/dataOpen/dataExport',
        method: 'GET',
        params: query,
        responseType: 'blob'
    })
}
export function getTemplateDownLoadUrl() {
    return baseRequest({
        url: '/catalog/dataOpen/getTemplatesPath',
        method: 'GET'
    })
}
export function getRelateAppList(params) {
    return baseRequest({
        url: '/application/open/page',
        method: 'post',
        data: params
    })
}
export function getCatalogApplyInfo(params) {
    return baseRequest({
        url: '/catalog/opencatalogapply/getCatalogApplyInfo',
        method: 'post',
        params
    })
}

// 获取数源部门树信息
export function getDeptTreeList(params){
    return baseRequest({
        url: '/catalog/dataOpen/getDeptTreeList',
        method: 'post',
        params
    })
}