<template>
    <a-config-provider :locale="locale">
        <div id="app">
            <template v-if="$route.name != 'Login' && $route.name != 'TransferLoad' && $route.name != 'zzdingLogin'">
                <Nav-header></Nav-header>
                <div class="conternt">
                    <router-view />
                </div>
                <Footer></Footer>
            </template>
            <template v-else>
                <router-view />
                <!--开放指数单独尾部-->
                <!-- <template v-if="$route.name === 'OpenIndexIndex'">
                    <Footer></Footer>
                </template> -->
            </template>
        </div>
    </a-config-provider>
</template>
<script>
import NavHeader from './components/NavHeader'
import Footer from './components/Footer'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { _script } from '@/config/script.js'
export default {
    metaInfo: {
        title: window.globalData.title
    },
    data() {
        return {
            name: 'APP',
            locale: zhCN,
            meta: [
                { name: 'SiteName', content: window.globalData.SiteName },
                { name: 'SiteDomain', content: window.globalData.SiteDomain },
                { name: 'SiteIDCode', content: window.globalData.SiteIDCode }
            ]
        }
    },
    created() {
        this.createScript(_script[window.globalData.city.name]['src'])
        this.setMenu()
    },
    components: { NavHeader, Footer },
    methods: {
        setMenu() {
            for (let item of this.meta) {
                let flag = false
                for (let meta of document.querySelectorAll('meta')) {
                    if (meta.hasAttribute('name') && meta.getAttribute('name') === item.name) {
                        meta.setAttribute('content', item.content)
                        flag = true
                        break
                    }
                }
                if (!flag) {
                    let el = document.createElement('meta')
                    el.setAttribute('name', item.name)
                    el.setAttribute('content', item.content)
                    let firstLink = document.querySelectorAll('head>link')[0]
                    document.getElementsByTagName('head')[0].insertBefore(el, firstLink)
                }
            }
        },
        createScript(src = '', text) {
            let _script = document.createElement('script')
            src && _script.setAttribute('src', src)
            if (text) {
                _script.text = text
            }
            document.getElementsByTagName('body')[0].appendChild(_script)
        }
    }
}
</script>
<style lang="scss">
* {
    margin: 0;
    padding: 0;
}
#app {
    /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
    /*-webkit-font-smoothing: antialiased;*/
    /*-moz-osx-font-smoothing: grayscale;*/
    color: #2c3e50;
    width: 100vw;
    // overflow-x: hidden;
    // overflow-y: auto;
    // overflow: auto;
}
.conternt {
    width: 100%;
    display: block;
    min-height: calc(100vh - 372px);
    background: #fff;
    position: relative;
    z-index: 1;
}
</style>
