import { loginRequest } from '@/API/login.js'

//获取加密信息
export function Login(JSON) {
    return loginRequest({
        // url: '/interface/resourceservice/myusedservice',
        url: '/smc-ac/accounts/authorize',
        method: 'POST',
        data: JSON
    })
}

//login 获取用户id
export function LoginInfo(JSON) {
    return loginRequest({
        url: '/usercenter/userinfo/detail',
        method: 'POST',
        data: JSON
    })
}

// quit
export function Quit(JSON) {
    return loginRequest({
        url: '/smc-ac/accounts/unauthorize',
        method: 'POST',
        data: JSON
    })
}
