import { getCookie, delCookie } from '@/utils/cookie'
import { message } from 'ant-design-vue'
import store from '../store/index'

const axios = require('axios')
axios.default.withCredentials = true

let ulrbase = ''
const urlAPI = window.globalData.BaseUrl
const HomeUrl = window.globalData.HomeUrl
if (process.env.NODE_ENV === 'development') {
    ulrbase = '/api'
} else {
    ulrbase = urlAPI
}
function validata(status) {
    switch (status) {
        case 10001:
            message.error('参数无效')
            break
        case 10002:
            message.error('参数为空')
            break
        case 10003:
            message.error('参数类型错误')
            break
        case 10004:
            message.error('参数缺失')
            break
        case 10005:
            message.error('SQL异常')
            break
        case 10006:
            message.error('SQL异常') //SQL语法异常
            break
        case 20001:
            message.error('您还未登录')
            break
        case 20002:
            message.error('账号不存在或密码错误')
            break
        case 20003:
            message.error('账号已被禁用')
            break
        case 20004:
            message.error('用户不存在')
            break
        case 20005:
            message.error('用户已存在')
            break
        case 30001:
            message.error('业务出现问题')
            break
        case 40001:
            message.error('系统繁忙，请稍后重试')
            break
        case 50001:
            message.error('数据未找到')
            break
        case 50002:
            message.error('数据有误')
            break
        case 50003:
            message.error('数据已存在')
            break
        case 60001:
            message.error('内部系统接口调用异常')
            break
        case 60002:
            message.error('外部系统接口调用异常')
            break
        case 60003:
            message.error('该接口禁止访问')
            break
        case 60004:
            message.error('接口地址无效')
            break
        case 60005:
            message.error('接口请求超时')
            break
        case 60006:
            message.error('接口负载过高')
            break
        case 70001:
            message.error('无访问权限')
            break
        case 222:
            message.error('单据状态不符')
            break
        case 400:
            message.error('请求出错')
            break
        case 401:
            message.warning('授权失败，请重新登录')
            // window.location.href = LoginURL
            return
        case 403:
            message.warning('拒绝访问')
            break
        case 404:
            message.warning('请求错误,未找到该资源')
            break
        case 500:
            message.warning('服务端错误')
            break
    }
}

/* request 请求结束开始 */
let baseRequest = axios.create({
    baseURL: ulrbase,
    timeout: window.globalData.requestTimeout, // 通过全局变量配置超时时间
    headers: {
        // 'X-Subject-token': getCookie('kf_token')||this.$store.state.kf_token,
        'Content-Type': 'application/json; charset=utf-8'
    },
    validateStatus(status) {
        if (status == 401 || status == 403) {
            delCookie('kf_token')
            delCookie('KF_USER')
            store.commit('setToken', '')
            store.commit('setUSER', '')
            // message.warning('授权失败，开发环境请重新登录获取token')
            // message.warning('抱歉，您访问的页面需要登录后才能访问')
            // message.warning('抱歉，部分功能登录后才能使用')
        } else {
            validata(status)
        }
        return status >= 200 && status < 300
    }
})

baseRequest.interceptors.request.use(
    config => {
        if (getCookie('kf_token')) {
            config.headers.common['X-Subject-token'] = getCookie('kf_token') || this.$store.state.kf_token
        }
        return config
    },
    err => {
        return Promise.resolve(err)
    }
)
baseRequest.interceptors.response.use(
    response => {
        if (response.data.code !== 200 && response.data.code !== '200') {
            // message.error(response.data.msg || '接口异常！')

            validata(response.data.code)
        } else {
            return response.data.data
        }
    },
    error => {
        // return Promise.reject(error.response.data)
        // debugger
        if (!error.response) {
            message.error('请求超时，请稍后重试')
            return Promise.reject()
        }
        let {
            status,
            data: { msg }
        } = error.response
        switch (status) {
            case 451:
                message.error(msg || '服务端错误')

                break
            case 500:
                message.error(msg || '服务端错误')
                break
        }
        return Promise.reject(error.response)
    }
)

/* 前端埋点*/
let trackRequest = axios.create({
    baseURL: ulrbase,
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    }
})
trackRequest.interceptors.request.use(
    config => {
        if (getCookie('kf_token')) {
            config.headers.common['X-Subject-Token'] = getCookie('kf_token') || this.$store.state.kf_token
        }
        return config
    },
    err => {
        return Promise.reject(err)
    }
)

trackRequest.interceptors.response.use(
    response => {
        return response
    },
    error => {
        return Promise.reject(error)
        // permission(error.response)
    }
)

/* 上传 请求结束开始 */
let uploadRequest = axios.create({
    headers: {
        'Content-Type': 'multipart/form-data'
    }
})

uploadRequest.interceptors.response.use(
    response => {
        return response.data.data
    },
    error => {
        return Promise.reject(error.response)
        // permission(error.response)
    }
)

export { baseRequest, urlAPI, HomeUrl, trackRequest, uploadRequest }
