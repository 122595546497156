<template>
    <div class="float-box">
        <div class="title-box">
            <span>{{ title }}</span>
            <span v-if="type == 'delete'"><a-icon type="delete" @click="handleDeleteClick" class="blue pointer" /></span>
        </div>
        <div class="border-box"></div>
        <div>
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: 'FloatBox',
    props: {
        title: {
            type: String
        },
        type: {
            type: String
        }
    },
    methods: {
        handleDeleteClick() {
            this.$emit('deleteClick')
        }
    }
}
</script>

<style lang="scss" scoped>
.float-box {
    background-color: #ffffff;
    width: 284px;
    box-sizing: border-box;
    margin-bottom: 25px;
    .title-box {
        margin-left: 30px;
        width: 220px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: Source Han Sans CN;
        font-size: $fs16;
        letter-spacing: 0px;
        color: #333333;
        i {
            color: #1890ff;
            font-size: $fs14;
        }
    }
    .border-box {
        margin-left: 30px;
        width: 220px;
        height: 4px;
        background-color: #333333;
    }
}
</style>
