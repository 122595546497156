<template>
    <div class="Layout">
        <keep-alive>
            <router-view v-if="$route.meta.keepalive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepalive"></router-view>
    </div>
</template>

<script>
export default {
    name: 'Layout',
    provide() {
        return {
            reload: this.reload
        }
    },
    data() {
        return { isRouterAlive: true }
    },
    methods: {
        reload() {
            this.isRouterAlive = false
            this.$nextTick(function() {
                this.isRouterAlive = true
            })
        }
    },
    mounted() {}
}
</script>

<style scoped lang="scss">
.Layout {
}
</style>
