<template>
    <a-modal centered closable :footer="null" v-model="visible" :maskClosable="true" @cancel="cancel" class="login-modal">
        <main>
            <p>您访问的链接需要登录认证</p>
            <div>
                <p>
                    请登录
                </p>
                <a-button type="primary" @click="goLogin(0)">
                    <span>个人登录/注册</span>
                </a-button>
                <a-button type="success" @click="goLogin(1)">
                    <span>法人登录/注册</span>
                </a-button>
                <a-button type="success" @click="goLogin(2)">
                    <span>浙政钉登录</span>
                </a-button>
            </div>
        </main>
    </a-modal>
</template>

<script>
import { thirdPartyPersonalLoginUrl, thirdPartyCompanyLoginUrl } from '@/config/setting.js'
export default {
    props: {},
    data() {
        return {
            visible: true
        }
    },
    computed: {},
    created() {},
    mounted() {},
    methods: {
        changeVisible() {},
        cancel() {
            this.$emit('cancel')
        },
        goLogin(type) {
            //0:个人登录；1：法人登录；2：浙政钉登录
            if (type === 0) {
                window.location.href = thirdPartyPersonalLoginUrl
            } else if (type === 1) {
                window.location.href = thirdPartyCompanyLoginUrl
            } else if (type === 2) {
                this.$router.push({ path: '/zzdingLogin' })
            }
        }
    },
    components: {}
}
</script>

<style scoped lang="scss">
.login-modal {
    font-size: 18px;
    text-align: center;
    padding: 24px 0;
    main {
        padding: 0 0 40px 0;
        > p {
            font-size: 18px;
            text-align: center;
            padding: 40px 0;
        }
        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            > p {
            }
            button {
                margin-left: 18px;
                text-align: center;
            }
        }
    }
}
</style>
