<template>
    <div>
        <a-modal class="gologin" :visible="openModal" @cancel="handleCancel" :centered="true" :okText="okText" :footer="null">
            <h1 class="head">{{ title }}</h1>
            <p class="info">{{ ModalText }}</p>
            <div class="btn">
                <a-button type="primary" icon="right" @click="handleOk">
                    跳转
                </a-button>
            </div>
        </a-modal>
    </div>
</template>
<script>
export default {
    name: 'jumpLogin',
    props: {
        openModal: Boolean
    },
    data() {
        return {
            okText: '跳转',
            title: '是否跳转到登录页？',
            ModalText: '检查到您为游客，您访问的链接需要登录认证'
        }
    },
    mounted() {},
    methods: {
        handleOk() {
            this.$emit('changeOpenModal', false)
            this.$router.replace('/Login')
        },
        handleCancel() {
            this.$emit('changeOpenModal', false)
        }
    }
}
</script>
<style lang="scss" scope>
.gologin {
    .head {
        margin: 20px auto;
        text-align: center;
        font-size: 24px;
        font-weight: 600;
    }
    .info {
        color: rgba(0, 0, 0, 0.85);
        margin: 10px auto;
        text-align: center;
    }
    .btn {
        display: flex;
        justify-content: flex-end;
        margin: 24px auto 10px;
    }
}
</style>
