let state = {
    isIndex: false,
    isUserCenter: false,
    // orgOptions: [],
    dataDomainOptions: [],
    resClassOptions: [],
    catalogDeptsList: [],
    catalogDomainList: [],
    searchHistoryList: [], //搜索历史列表
    kf_token: null,
    KF_USER: null,
    areaCode:null,
}
export default state
