import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFound from '../components/NotFound' //404页面
import Login from '@/views/Login/index'
import TransferLoad from '@/views/TransferLoad/index'
import TransferLoadLegal from '@/views/TransferLoadLegal/index'
import Layout from '../components/Layout'
import SubFramework from '../components/SubFramework'
import { GetUserInfo } from '@/API/http'
/* 全局路由守卫 */
import { getCookie, setCookie } from '@/utils/cookie'
import { trackRequest } from '@/API'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
//埋点
function track() {
    return trackRequest({
        url: '/usercenter/stat/tracking',
        method: 'POST',
        data: { type: '0' }
    })
}

const routes = [
    {
        path: '/',
        redirect: '/Home'
    },
    {
        path: '/404',
        name: '404',
        component: NotFound
    },
    {
        path: '/transferLoad',
        component: TransferLoad,
        name: 'TransferLoad',
        meta: {
            name: ''
        }
    },
    {
        path: '/transferLoadLegal',
        component: TransferLoadLegal,
        name: 'TransferLoadLegal',
        meta: {
            name: ''
        }
    },
    {
        path: '/Login',
        component: Login,
        name: 'Login',
        meta: {
            name: '登录'
        }
    },
    {
        path: '/zzdingLogin',
        component: () => import(/* webpackChunkName: "Home" */ '@/views/Login/zzdingLogin'),
        name: 'zzdingLogin',
        meta: {
            name: '浙政钉登录'
        }
    },
    {
        path: '/Home',
        name: 'Home',
        component: Layout,
        redirect: '/Home/Default',
        children: [
            {
                path: '/Home/Default',
                redirect: '/Home/Default/Index',
                component: SubFramework,
                name: 'Default',
                meta: {
                    name: '首页默认'
                },
                children: [
                    {
                        path: 'Index',
                        component: () => import(/* webpackChunkName: "Home" */ '../views/Home/Index'),
                        name: 'Index',
                        meta: {
                            keepalive: true,
                            name: '首页',
                            metaInfo: window.globalData.metaInfo.Index
                        }
                    },
                    {
                        path: 'Search',
                        component: () => import(/* webpackChunkName: "Home" */ '../views/Home/Search'),
                        name: 'Search',
                        meta: {
                            name: '搜索',
                            metaInfo: window.globalData.metaInfo.Index
                        }
                    },
                    {
                        path: 'UserCenter',
                        component: () => import(/* webpackChunkName: "Home" */ '../views/Home/UserCenter'),
                        name: 'UserCenter',
                        meta: {
                            name: '个人中心'
                        },
                        redirect: '/Home/Default/UserCenter/MyApply',
                        children: [
                            {
                                path: 'MyApply',
                                component: SubFramework,
                                // component: () => import(/* webpackChunkName: "Home" */ '../views/Home/MyApply/index'),
                                name: 'MyApply',
                                meta: {
                                    keepalive: true,
                                    name: '我的申请'
                                },
                                redirect: '/Home/Default/UserCenter/MyApply/MyApplyList',
                                children: [
                                    {
                                        path: 'MyApplyList',
                                        component: () => import(/* webpackChunkName: "Home" */ '../views/Home/MyApply/MyApplyList.vue'),
                                        name: 'MyApplyList',
                                        meta: {
                                            keepalive: true,
                                            name: '申请列表',
                                            metaInfo: window.globalData.metaInfo.MyApply
                                        }
                                    },
                                    {
                                        path: 'MyApplyDetail',
                                        component: () => import(/* webpackChunkName: "Home" */ '../views/Home/MyApply/MyApplyDetail.vue'),
                                        name: 'MyApplyDetail',
                                        meta: {
                                            name: '申请详情',
                                            metaInfo: window.globalData.metaInfo.MyApply
                                        }
                                    },
                                    {
                                        path: 'MyLimitApplyDetail',
                                        component: () => import(/* webpackChunkName: "Home" */ '../views/Home/MyApply/MyLimitApplyDetail.vue'),
                                        name: 'MyLimitApplyDetail',
                                        meta: {
                                            name: '受限目录申请详情',
                                            metaInfo: window.globalData.metaInfo.MyApply
                                        }
                                    }
                                ]
                            },
                            {
                                path: 'MyApp',
                                component: SubFramework,
                                name: 'MyApp',
                                meta: {
                                    name: '我的应用'
                                },
                                redirect: '/Home/Default/UserCenter/MyApp/MyAppList',
                                children: [
                                    {
                                        path: 'MyAppList',
                                        component: () => import(/* webpackChunkName: "Home" */ '../views/Home/MyApp/MyAppList.vue'),
                                        name: 'MyAppList',
                                        meta: {
                                            name: '我的应用列表',
                                            metaInfo: window.globalData.metaInfo.MyApp
                                        }
                                    },
                                    {
                                        path: 'MyAppDetail',
                                        component: () => import(/* webpackChunkName: "Home" */ '../views/Home/MyApp/MyAppDetail'),
                                        name: 'MyAppDetail',
                                        meta: {
                                            name: '我的应用详情',
                                            metaInfo: window.globalData.metaInfo.MyApp
                                        }
                                    },
                                    {
                                        path: 'MyAppEdit',
                                        component: () => import(/* webpackChunkName: "Home" */ '../views/Home/MyApp/MyAppEdit'),
                                        name: 'MyAppEdit',
                                        meta: {
                                            name: '我的应用编辑',
                                            metaInfo: window.globalData.metaInfo.MyApp
                                        }
                                    }
                                ]
                            },
                            {
                                path: 'MyService',
                                component: SubFramework,
                                name: 'MyService',
                                meta: {
                                    keepalive: true,
                                    name: '我的服务'
                                },
                                redirect: '/Home/Default/UserCenter/MyService/MyServiceList',
                                children: [
                                    {
                                        path: 'MyServiceList',
                                        component: () => import(/* webpackChunkName: "Home" */ '../views/Home/MyService/MyServiceList.vue'),
                                        name: 'MyServiceList',
                                        meta: {
                                            keepalive: true,
                                            name: '申请列表',
                                            metaInfo: window.globalData.metaInfo.MyService
                                        }
                                    },
                                    {
                                        path: 'MyServiceDetail',
                                        component: () => import(/* webpackChunkName: "Home" */ '../views/Home/MyService/MyServiceDetail.vue'),
                                        name: 'MyServiceDetail',
                                        meta: {
                                            name: '我的服务详情',
                                            metaInfo: window.globalData.metaInfo.MyService
                                        }
                                    }
                                ]
                            },
                            {
                                path: 'MyCollect',
                                component: SubFramework,
                                name: 'MyCollect',
                                meta: {
                                    keepalive: true,
                                    name: '我的收藏'
                                },
                                redirect: '/Home/Default/UserCenter/MyCollect/MyCollectList',
                                children: [
                                    {
                                        path: 'MyCollectList',
                                        component: () => import(/* webpackChunkName: "Home" */ '../views/Home/MyCollect/MyCollectList.vue'),
                                        name: 'MyCollectList',
                                        meta: {
                                            keepalive: true,
                                            name: '我的收藏列表',
                                            metaInfo: window.globalData.metaInfo.MyCollect
                                        }
                                    }
                                    /*{
										path: 'MyCollectDetail',
										component: () => import(/!* webpackChunkName: "Home" *!/ '../views/Home/MyCollect/MyCollectDetail.vue'),
										name: 'MyCollectDetail',
										meta: {
											name: '我的能力详情',
											keepalive: true
										}
									}*/
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        path: '/OpenData',
        meta: {
            name: '开放数据'
        },
        component: Layout,
        redirect: '/OpenData/DataSet',
        name: 'OpenData',
        children: [
            {
                path: '/OpenData/DataSet',
                redirect: '/OpenData/DataSet/List',
                component: SubFramework,
                name: 'DataSet',
                meta: {
                    name: '数据集'
                },
                children: [
                    {
                        path: 'List',
                        component: () => import(/* webpackChunkName: "DataCatalog" */ '../views/OpenData/DataSet/List'),
                        name: 'DataSetList',
                        meta: {
                            name: '列表',
                            keepalive: true,
                            metaInfo: window.globalData.metaInfo.DataSet
                        }
                    },
                    {
                        path: 'Detail',
                        component: () => import(/* webpackChunkName: "DataCatalog" */ '../views/OpenData/DataSet/Detail'),
                        name: 'DataSetDetail',
                        meta: {
                            name: '详情',
                            metaInfo: window.globalData.metaInfo.DataSet
                        }
                    },
                    {
                        path: 'Visualization',
                        component: () => import(/* webpackChunkName: "DataCatalog" */ '../views/OpenData/DataSet/Visualization'),
                        name: 'DataVisualization',
                        meta: {
                            name: '数据可视化',
                            metaInfo: window.globalData.metaInfo.DataSet
                        }
                    }
                ]
            },
            {
                path: '/OpenData/DataCatalog',
                redirect: '/OpenData/DataCatalog/List',
                component: SubFramework,
                name: 'DataCatalog',
                meta: {
                    name: '数据目录'
                },
                children: [
                    {
                        path: 'List',
                        component: () => import(/* webpackChunkName: "DataCatalog" */ '../views/OpenData/DataCatalog/List'),
                        name: 'DataCatalogList',
                        meta: {
                            name: '列表',
                            metaInfo: window.globalData.metaInfo.DataCatalog

                            // keepalive: true
                        }
                    },
                    {
                        path: 'Application',
                        component: () => import(/* webpackChunkName: "DataCatalog" */ '../views/OpenData/DataCatalog/Application'),
                        name: 'DataCatalogApplication',
                        meta: {
                            name: '申请',
                            metaInfo: window.globalData.metaInfo.DataCatalog
                            // keepalive: true
                        }
                    },
                    {
                        path: 'Edit',
                        component: () => import(/* webpackChunkName: "DataCatalog" */ '../views/OpenData/DataCatalog/Edit'),
                        name: 'DataCatalogEdit',
                        meta: {
                            name: '修改',
                            metaInfo: window.globalData.metaInfo.DataCatalog
                            // keepalive: true
                        }
                    }
                ]
            }
        ]
    },
    {
        path: '/InterfaceService',
        meta: {
            name: '接口服务'
        },
        name: 'InterfaceService',
        component: Layout,
        redirect: '/InterfaceService/List',
        children: [
            {
                path: '/InterfaceService/List',
                component: () => import(/* webpackChunkName: "DataInterfaceDemand" */ '../views/InterfaceService/List'),
                name: 'InterfaceServiceList',
                meta: {
                    keepalive: true,
                    name: '接口服务列表',
                    metaInfo: window.globalData.metaInfo.InterfaceService
                }
            },
            {
                path: '/InterfaceService/Application/:id',
                component: () => import(/* webpackChunkName: "DataInterfaceDemand" */ '../views/InterfaceService/Application'),
                name: 'InterfaceServiceApplication',
                meta: {
                    name: '接口服务申请',
                    metaInfo: window.globalData.metaInfo.InterfaceService
                }
            },
            {
                path: '/InterfaceService/Edit/:id',
                component: () => import(/* webpackChunkName: "DataInterfaceDemand" */ '../views/InterfaceService/Edit'),
                name: 'InterfaceServiceEdit',
                meta: {
                    name: '接口服务申请修改',
                    metaInfo: window.globalData.metaInfo.InterfaceService
                }
            },
            {
                path: '/InterfaceService/Detail',
                component: () => import(/* webpackChunkName: "DataInterfaceDemand" */ '../views/InterfaceService/Detail'),
                name: 'InterfaceServiceDetail',
                meta: {
                    name: '接口服务详情',
                    metaInfo: window.globalData.metaInfo.InterfaceService
                }
            },
            {
                path: '/InterfaceService/DataSetDetail',
                component: () => import(/* webpackChunkName: "DataInterfaceDemand" */ '../views/InterfaceService/DataSetDetail'),
                name: 'InterfaceServiceDataSetDetail',
                meta: {
                    name: '数据集接口详情',
                    metaInfo: window.globalData.metaInfo.InterfaceService
                }
            }
        ]
    },
    {
        path: '/ApplicationResults',
        meta: {
            name: '应用成果'
        },
        name: 'ApplicationResults',
        component: Layout,
        redirect: '/ApplicationResults/List',
        children: [
            {
                path: '/ApplicationResults/List',
                component: () => import(/* webpackChunkName: "DataInterfaceDemand" */ '../views/ApplicationResults/List'),
                name: 'ApplicationResultsList',
                meta: {
                    keepalive: true,
                    name: '应用成果列表',
                    metaInfo: window.globalData.metaInfo.ApplicationResults
                }
            },
            {
                path: '/ApplicationResults/Detail/:id',
                component: () => import(/* webpackChunkName: "DataInterfaceDemand" */ '../views/ApplicationResults/Detail'),
                name: 'ApplicationResultsDetail',
                meta: {
                    name: '应用成果详情',
                    metaInfo: window.globalData.metaInfo.ApplicationResults
                }
            }
        ]
    },
    {
        path: '/DeveloperCenter',
        meta: {
            name: '开发者中心'
        },
        name: 'DeveloperCenter',
        component: Layout,
        redirect: '/DeveloperCenter/Index',
        children: [
            {
                path: '/DeveloperCenter/Index',
                component: () => import(/* webpackChunkName: "DataInterfaceDemand" */ '../views/DeveloperCenter/Index'),
                name: 'DeveloperCenterIndex',
                meta: {
                    name: '开发者中心首页',
                    metaInfo: window.globalData.metaInfo.DeveloperCenter
                }
            },
            {
                path: '/DeveloperCenter/List/:id/:navIndex',
                component: () => import(/* webpackChunkName: "DataInterfaceDemand" */ '../views/DeveloperCenter/List'),
                name: 'DeveloperCenterList',
                meta: {
                    name: '列表',
                    metaInfo: window.globalData.metaInfo.DeveloperCenter
                }
            }
        ]
    },
    {
        path: '/OpenIndex',
        meta: {
            name: '开放指数'
        },
        name: 'OpenIndex',
        component: Layout,
        redirect: '/OpenIndex/Index',
        children: [
            {
                path: '/OpenIndex/Index',
                component: () => import(/* webpackChunkName: "DataInterfaceDemand" */ '../views/OpenIndex/Index'),
                name: 'OpenIndexIndex',
                meta: {
                    name: '开放指数',
                    metaInfo: window.globalData.metaInfo.OpenIndex
                }
            }
        ]
    },
    {
        path: '/InteractiveCommunication',
        meta: {
            name: '互动交流'
        },
        name: 'InteractiveCommunication',
        component: Layout,
        redirect: '/InteractiveCommunication/Index',
        children: [
            {
                path: '/InteractiveCommunication/Index',
                component: () => import(/* webpackChunkName: "DataInterfaceDemand" */ '../views/InteractiveCommunication/Index'),
                name: 'InteractiveCommunicationIndex',
                meta: {
                    name: '互动交流'
                }
            }
        ]
    },
    {
        path: '/PolicyTrends',
        meta: {
            name: '政策动态'
        },
        component: Layout,
        name: 'PolicyTrends',
        redirect: '/PolicyTrends/Index',
        children: [
            {
                path: '/PolicyTrends/Index',
                component: () => import(/* webpackChunkName: "DataInterfaceDemand" */ '../views/PolicyTrends/Index'),
                name: 'PolicyTrendsIndex',
                meta: {
                    name: '政策动态',
                    metaInfo: window.globalData.metaInfo.PolicyTrends
                }
            },
            {
                path: '/PolicyTrends/Detail/:id/:breadTitl',
                component: () => import(/* webpackChunkName: "DataInterfaceDemand" */ '../views/PolicyTrends/Detail'),
                name: 'PolicyTrendsDetail',
                meta: {
                    name: '详情',
                    metaInfo: window.globalData.metaInfo.PolicyTrends
                }
            }
        ]
    },
    {
        path: '/ContactUs',
        meta: {
            name: '联系我们'
        },
        component: () => import(/* webpackChunkName: "DataInterfaceDemand" */ '../views/ContactUs/index'),
        name: 'ContactUs'
    },
    {
        path: '/SiteMap',
        meta: {
            name: '网站地图'
        },
        component: () => import(/* webpackChunkName: "DataInterfaceDemand" */ '../views/SiteMap/index'),
        name: 'SiteMap'
    },
    {
        path: '/SiteStatement',
        name: 'SiteStatement',
        component: Layout,
        redirect: '/SiteStatement/Index',
        children:[
            {
                path: '/SiteStatement/Index',
                component: () => import(/* webpackChunkName: "Home" */ '../views/Home/SiteStatement'),
                name: 'SiteStatementIndex',
            }
        ],
        meta: {
            name: '网站声明'
        }
    },
    { path: '*', redirect: '/Home/Default/Index' }
]

const router = new VueRouter({
    routes
})
export default router

router.beforeEach((to, from, next) => {
    // debugger
    if (to.path == '/transferLoad') {
    }
    // 全局存Cookie
    //         ?objData={"token":"70ac0b22e1fd429eba5e2d40420d61c5%3AtrmNKalJ%2Bcue7fCYx3NAxBwTbq3%2B5VM3QU3oNSoIJmnJkf2RAF%2F2frfLTmrGOmIvVh5cnVl%2BtG5bOie0%2FK3QfKBTiZHLqpJWLhkUeCAKgu%2BE8yW8F0G1lzVR4Q%2FdEzFe%2FWscex8YTzztvUlnyKgxcQ%3D%3D","appid":"d09e22c59d6742d4908ad1c589fefb2b","reallyname":"zsadmin","username":"70ac0b22e1fd429eba5e2d40420d61c5","nvaname":"舟山市政府"}
    /* 动态改动站点title */
    if (to.meta.title) {
        document.title = to.meta.title
    }

    let query = to.query
    if (query.objData) {
        setCookie('kf_token', JSON.parse(query.objData).token)
        GetUserInfo(JSON.parse(query.objData).username).then(res => {
            setCookie('KF_USER', JSON.stringify(res))
        })
    }

    // if (to.name != 'MyApplyList') {
    next()
    // } else {
    //     // 判断是否需要登录权限
    //     if (getCookie('kf_token')) {
    //         // 判断当前的token是否存在
    //         next()
    //     } else {
    //         next({
    //             path: '/Login'
    //         })
    //     }
    track()
    // }
})
router.afterEach(to => {
    if (to.meta.metaInfo && Array.isArray(to.meta.metaInfo) && to.meta.metaInfo.length) {
        let metaInfo = to.meta.metaInfo
        for (let item of metaInfo) {
            let flag = false
            for (let meta of document.querySelectorAll('meta')) {
                if (meta.hasAttribute('name') && meta.getAttribute('name') === item.name) {
                    meta.setAttribute('content', item.content)
                    flag = true
                    break
                }
            }
            if (!flag) {
                let el = document.createElement('meta')
                el.setAttribute('name', item.name)
                el.setAttribute('content', item.content)
                let firstLink = document.querySelectorAll('head>link')[0]
                document.getElementsByTagName('head')[0].insertBefore(el, firstLink)
            }
        }
    }
})
