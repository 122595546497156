let mixin = {
    data: () => {
        return {
            window,
            city: window.globalData.city
        }
    },
    methods: {},
    mounted() {
        // console.log(this.city)
    }
}

export default mixin
