<template>
    <div class="trans-info-list">
        <div class="trans-info-item" v-for="(item, index) in data" :key="index">
            <p>
                <span class="name">{{ item.handleMan || item.operator }}</span>
                {{ item.handleInfo || item.opinions }}
                <!--     【 <span class="normal">{{ item.status }}</span> 】-->
            </p>
            <span class="date">{{ dateInit(item.date || item.createDate) }}</span>
        </div>
    </div>
</template>

<script>
import { formatTime, verifyTime } from '@/utils/tools'
export default {
    name: 'ApprovalProcess',
    props: {
        data: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    methods: {
        dateInit(dat) {
            let date = new Date(dat)
            let month = date.getMonth() + 1
            let day = date.getDate()
            return verifyTime(month) + '-' + verifyTime(day) + ' ' + formatTime(dat, true)
        }
    }
}
</script>

<style lang="scss" scoped>
.trans-info-list {
    max-height: 120px;
    overflow-y: auto;
    .trans-info-item {
        display: flex;
        flex-flow: row nowrap;
        align-content: center;
        margin-bottom: 16px;
        font-size: $fs12;
        > p {
            .name {
                display: inline-block;
                max-width: 160px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
                vertical-align: bottom;
            }
            span.normal {
                color: #0184ff;
            }
            span.warn {
                color: #ff0101;
            }
        }
        .date {
            margin-left: auto;
            height: $fs11;
            font-size: $fs11;
            color: #8e92a1;
            flex-shrink: 0;
        }
    }
}
.trans-info-list::-webkit-scrollbar {
    width: 4px;
    /*height: 4px;*/
}
.trans-info-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
}
.trans-info-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
}
</style>
