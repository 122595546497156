<template>
    <div></div>
</template>

<script>
import { thirdPartyLogin, GetUserInfo } from '@/API/http'
import { setCookie } from '@/utils/cookie'
export default {
    name: 'TransferLoad',
    data() {
        return {}
    },
    components: {},
    created() {},
    mounted() {
        // this.$router.push('/')
    },
    methods: {
        getUserInfo(userCode) {
            GetUserInfo(userCode)
                .then(res => {
                    let KF_USER = JSON.stringify(res)
                    setCookie('KF_USER', KF_USER)
                    this.$store.commit('setUSER', KF_USER)
                    // debugger
                    this.$router.replace('/')
                })
                .catch(() => {
                    this.$message.error('获取用户信息失败')
                    this.$router.replace('/')
                })
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            // 通过 `vm` 访问组件实例
            // console.log('to', to)
            let query = to.query || {}
            // debugger
            if (query.ticket || query.ssotoken || query.id_token) {
                //存在就调用获取token接口
                let userType = ''
                let params = {}
                if (query.ticket) {
                    params.userType = 1
                    params.ticket = query.ticket
                } else if (query.ssotoken) {
                    params.userType = 2
                    params.ssotoken = query.ssotoken
                } else if (query.id_token) {
                    params.userType = 4
                    params.id_token = query.id_token
                }
                setCookie('userType', userType)
                thirdPartyLogin({ ...params })
                    .then(res => {
                        console.log('thirdPartyLogin=>', res)
                        setCookie('kf_token', res.token)
                        setCookie('userCode', res.userCode)
                        setCookie('userName', res.userName)
                        vm.$store.commit('setToken', res.token)
                        vm.getUserInfo(res.userCode)
                    })
                    .catch(err => {
                        vm.$message.error('登录失败，请重新登录')
                        vm.$router.replace('/') //跳首页
                        console.log('err=>', err)
                    })
            } else {
                vm.$router.replace('/') //跳首页
            }
        })
    }
}
</script>

<style scoped lang="scss"></style>
