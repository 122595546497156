;(() => {
    PromiseAllSettled()
})()

function PromiseAllSettled() {
    Promise.allSettled =
        Promise.allSettled ||
        function(funcArr) {
            return new Promise(resolve => {
                let sttled = 0
                let result = []
                for (let index = 0; index < funcArr.length; index++) {
                    const element = funcArr[index]
                    element
                        .then(res => {
                            result[index] = {
                                status: 'fulfilled',
                                value: res
                            }
                        })
                        .catch(err => {
                            result[index] = {
                                status: 'reason',
                                reason: err
                            }
                        })
                        .finally(() => {
                            ++sttled === funcArr.length && resolve(result)
                        })
                }
            })
        }
}
