<template>
    <div class="NotFound">
        <h1>404</h1>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
    data() {
        return {};
    },
    methods: {},
    mounted() {}
};
</script>

<style scoped lang="scss">
.NotFound {
}
</style>
