<template>
    <div class="footer">
        <div class="footer-main">
            <main>
                <ul>
                    <li @click="setHome('btn_setHome', window.location)" id="btn_setHome" ref="btn_setHome"><span>把本站设为首页</span></li>
                    <li>|</li>
                    <li @click="goSiteStatement"><span>网站声明</span></li>
                    <li><span>|</span></li>
                    <li @click="goDeveloperCenter"><span>新手指南</span></li>
                    <li><span>|</span></li>
                    <li @click="goSiteMap"><span>网站地图</span></li>
                    <li><span>|</span></li>
                    <li @click="goContactUs"><span>联系我们</span></li>
                </ul>
                <section>
                    <p>主办单位：{{ window.globalData.footerInfo.department }} 网站标识码：{{ window.globalData.footerInfo.biaoshima }}</p>
                    <p>
                        备案：
                        <span style="cursor:pointer;text-decoration:underline" @click="openLink(window.globalData.footerInfo.beianLink)">{{ window.globalData.footerInfo.beian }}</span>
                        <span class="icon" @click="openLink(window.globalData.footerInfo.beianPath)"><img :src="require('@/assets/police.png')" /></span>
                        <span style="cursor:pointer;text-decoration:underline" @click="openLink(window.globalData.footerInfo.beianPath)">{{ window.globalData.footerInfo.beianhao }}</span>
                        中文域名：{{ window.globalData.footerInfo.chineseDomain }}
                    </p>
                    <p>建议使用1920*1080分辨率/IE9.0或以上浏览器访问达到最佳效果</p>
                </section>
            </main>
            <aside>
                <img :src="require('@/assets/red.png')" alt="" @click="openLink(window.globalData.footerInfo.dangzhengjiguan)" />
            </aside>
            <aside>
                <img :src="require('@/assets/jiucuo.png')" alt="" @click="openLink(window.globalData.footerInfo.zhengfuwangzhanzhaocuo)" />
            </aside>
        </div>
        <!-- <img src="../assets/footer.png" class="footerimg" alt="" /> -->
    </div>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {}
    },
    methods: {
        setHome(ref, url) {
            try {
                let obj = document.getElementById(ref)
                obj.style.behavior = 'url(#default#homepage)'
                obj.setHomePage(url)
            } catch (e) {
                if (window.netscape) {
                    try {
                        netscape.security.PrivilegeManager.enablePrivilege('UniversalXPConnect')
                    } catch (e) {
                        alert("抱歉，此操作被浏览器拒绝！\n\n请在浏览器地址栏输入“about:config”并回车然后将[signed.applets.codebase_principal_support]设置为'true'")
                    }
                } else {
                    alert('抱歉，您所使用的浏览器无法完成此操作。\n\n您需要手动将【' + url + '】设置为首页。')
                }
            }
        },
        openLink(link) {
            window.open(link)
        },
        goSiteMap(){
            this.$router.push('/SiteMap')
        },
        goContactUs() {
            this.$router.push('/ContactUs')
        },
        goDeveloperCenter(){
            this.$router.push('/DeveloperCenter')
        },
        goSiteStatement(){
            this.$router.push('/SiteStatement')
        }
    },
    mounted() {}
}
</script>

<style scoped lang="scss">
.footer {
    width: 100%;
    height: 116px;
    text-align: center;
    color: white;
    background: #152640;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    .footer-main {
        width: 1200px;
        margin: 0 auto;
        color: #ccc;
        display: flex;
        justify-content: center;
        align-items: center;
        aside {
            flex-shrink: 0;
            margin-left: 14px;
            img {
                cursor: pointer;
            }
        }
        ul {
            display: flex;
            justify-content: center;
            li {
                padding: 0 4px;
                span {
                    cursor: pointer;
                }
            }
        }
        section {
            margin-top: 0px;
            p {
                padding-top: 4px;
                .icon {
                    padding: 0 2px 0 12px;
                    cursor: pointer;
                }
            }
        }
    }
    .footerimg {
        display: block;
        height: 100%;
        margin: 0 auto;
    }
}
</style>
