<template>
    <div class="search--Bar" v-search-input="blurInput">
        <div class="searchBar">
            <input @keyup.enter="goSearch" type="text" maxlength="32" @focus="focusInput" v-model.trim="keywords" @input="SerchInput" class="ipt" placeholder="查资源目录 / 数据集 / 接口资源" />
            <div class="searchBtn" @click="goSearch"><a-icon type="search"></a-icon></div>
        </div>
        <ul class="searchList" v-show="searchListShow && searchHistoryList.length">
            <li class="searchFirst">
                历史记录
                <a-icon type="delete" style="color: #3587fd;margin-left: 10px;cursor: pointer" @click="clearHisrory" />
            </li>
            <li class="list" v-for="(item, index) in searchHistoryList" :key="index" @click="changeValue(item)">{{ item }}</li>
        </ul>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { searchLog, clearHisrory } from '../api/index'
import { getCookie } from '@/utils/cookie'
export default {
    name: 'searchBar',
    data() {
        return {
            searchListShow: false,
            keywords: '',
            isLogin: false
        }
    },
    watch: {
        $route(newVal, oldVal) {
            if (oldVal.name == 'Search') {
                if (newVal.name != 'Search') {
                    this.keywords = ''
                }
            }

            if (getCookie('KF_USER') && getCookie('kf_token')) {
                this.isLogin = true
                // this.searchHistory()
            }
        },
        hasLoginToken(newVal, oldVal) {
            if (newVal != oldVal) {
                this.isLogin = false
            }
        }
    },
    computed: {
        hasLoginToken() {
            return this.$store.state.kf_token
        },
        ...mapState(['searchHistoryList'])
    },
    mounted() {
        if (getCookie('KF_USER') && getCookie('kf_token')) {
            this.isLogin = true
            // this.searchHistory()
            this.$store.dispatch('searchHistory')
        }
    },
    directives: {
        'search-input': {
            bind: function(el, binding) {
                //     /** el可以获取当前dom节点，并且进行编译，也可以操作事件 **/
                //     /** binding指的是一个对象，一般不用 **/
                //     /** vnode 是 Vue 编译生成的虚拟节点 **/
                function documentHandler(e) {
                    if (el.contains(e.target)) {
                        return false
                    }
                    if (binding.expression) {
                        binding.value(e)
                    }
                }
                el._vueClickOutside_ = documentHandler
                document.addEventListener('click', documentHandler)
            },
            unbind: function(el) {
                document.removeEventListener('click', el._vueClickOutside_)
                delete el._vueClickOutside_
            }
        }
    },
    methods: {
        //清空历史记录
        clearHisrory() {
            if (!this.searchHistoryList.length) return
            clearHisrory({
                name: ''
            })
                .then(() => {
                    this.$store.commit('setSearchHistoryList', [])
                })
                .catch(() => {
                    this.$message.warn('清空失败，请稍后再试！')
                })
        },
        focusInput() {
            if (this.isLogin) {
                this.searchListShow = true
            }
        },
        blurInput() {
            this.searchListShow = false
        },
        SerchInput() {
            if (this.isLogin) {
                if (this.keywords == '') {
                    this.searchListShow = true
                } else {
                    this.searchListShow = false
                }
            }
        },
        changeValue(value) {
            this.keywords = value
            this.searchListShow = false
        },
        goSearch() {
            this.searchListShow = false
            if (this.keywords) {
                this.$router.push({
                    name: 'Search',
                    query: {
                        keywords: this.keywords
                    }
                })
            }
        }
    }
}
</script>

<style scoped lang="scss">
.search--Bar {
    z-index: 10;
    width: 380px;
    display: block;
    margin: 0 auto;
    height: 40px;
    position: relative;
    .searchBar {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        .ipt {
            width: 338px;
            height: 40px;
            font-size: 16px;
            line-height: 40px;
            letter-spacing: 0px;
            color: rgba(37, 40, 44, 0.84);
            border: none;
            background: none;
            outline: none;
            box-sizing: border-box;
            position: absolute;
            left: 0;
            top: 0;
        }
        .searchBtn {
            width: 40px;
            height: 40px;
            background: #357df1;
            font-family: Source Han Sans CN;
            font-size: 16px;
            letter-spacing: 0px;
            color: #ffffff;
            cursor: pointer;
            text-align: center;
            line-height: 40px;
            position: absolute;
            right: 0;
            top: 0;
            border-radius: 20px;
        }
    }
    .searchList {
        width: 360px;
        height: 218px;
        display: block;
        position: absolute;
        left: 0;
        top: 40px;
        background: white;
        box-sizing: border-box;
        border: 1px solid #dbdbdb;
        .searchFirst {
            width: 100%;
            height: 36px;
            line-height: 36px;
            padding-left: 24px;
        }
        .list {
            line-height: 36px;
            padding-left: 24px;
            &:hover {
                background: #f4f5f6;
            }
        }
    }
}
</style>
