export const _script = {
    舟山: {
        src: 'https://sso.ding.zj.gov.cn/static/js/idaas-login-plugin.js',
        text: `var idaasLogin = new IDaaSLoginPlugin({
            serverUrl: "https://sso.ding.zj.gov.cn",
            portalUrl: "https://sso.ding.zj.gov.cn/frontend/login#/login",
            instanceId: "zzd",
            appId: "19e3e1333321eb72d1382c016d74ebd8908Fjd9hsl9",
            styleUrl: "",
            styleTxt: "",
            box: "login-container",
            width: 340,
            height: 410,
            target: true,
            loginType: ""
          });
          
          // 变更修改认证方式示例
          function handleChangeLoginType () {
            idaasLogin.changeLoginType("qr") // v1.6 新增方法(如果想手动修改认证方式，请调用 此方法，传值请参考“参数说明”中的“loginType”)
          }
        `
    },
    定海: {
        src: 'https://sso.ding.zj.gov.cn/static/js/idaas-login-plugin.js',
        text: `
        var idaasLogin = new IDaaSLoginPlugin({
            serverUrl: "https://sso.ding.zj.gov.cn",
            portalUrl: "https://sso.ding.zj.gov.cn/frontend/login#/login",
            instanceId: "zzd",
            appId: "850d56300640471a5f08edd6dd7ba98bA9QCqUj12z2",
            styleUrl: "",
            styleTxt: "",
            box: "login-container",
            width: 340,
            height: 410,
            target: true,
            loginType: ""
          });
          
          // 变更修改认证方式示例
          function handleChangeLoginType () {
            idaasLogin.changeLoginType("qr") // v1.6 新增方法(如果想手动修改认证方式，请调用 此方法，传值请参考“参数说明”中的“loginType”)
          }
        `
    },
    岱山: {
        src: 'https://sso.ding.zj.gov.cn/static/js/idaas-login-plugin.js',
        text: `
        var idaasLogin = new IDaaSLoginPlugin({
            serverUrl: "https://sso.ding.zj.gov.cn",
            portalUrl: "https://sso.ding.zj.gov.cn/frontend/login#/login",
            instanceId: "zzd",
            appId: "a5ea714310e0388e7cd27024dbdbed93yEAxQ3mSZP6",
            styleUrl: "",
            styleTxt: "",
            box: "login-container",
            width: 340,
            height: 410,
            target: true,
            loginType: ""
          });
          
          // 变更修改认证方式示例
          function handleChangeLoginType () {
            idaasLogin.changeLoginType("qr") // v1.6 新增方法(如果想手动修改认证方式，请调用 此方法，传值请参考“参数说明”中的“loginType”)
          }
        `
    },
    嵊泗: {
        src: 'https://sso.ding.zj.gov.cn/static/js/idaas-login-plugin.js',
        text: `var idaasLogin = new IDaaSLoginPlugin({
            serverUrl: "https://sso.ding.zj.gov.cn",
            portalUrl: "https://sso.ding.zj.gov.cn/frontend/login#/login",
            instanceId: "zzd",
            appId: "ab4e77ca104bfc83bbbb8a395d6738d8k95QMu4eN0W",
            styleUrl: "",
            styleTxt: "",
            box: "login-container",
            width: 340,
            height: 410,
            target: true,
            loginType: ""
          });
          
          // 变更修改认证方式示例
          function handleChangeLoginType () {
            idaasLogin.changeLoginType("qr") // v1.6 新增方法(如果想手动修改认证方式，请调用 此方法，传值请参考“参数说明”中的“loginType”)
          }`
    },
    普陀: {
        src: 'https://sso.ding.zj.gov.cn/static/js/idaas-login-plugin.js',
        text: `var idaasLogin = new IDaaSLoginPlugin({
            serverUrl: "https://sso.ding.zj.gov.cn",
            portalUrl: "https://sso.ding.zj.gov.cn/frontend/login#/login",
            instanceId: "zzd",
            appId: "baa2a8f80d674f86a7aaa4ce4cc23078Tyr5nZ2c2pq",
            styleUrl: "",
            styleTxt: "",
            box: "login-container",
            width: 340,
            height: 410,
            target: true,
            loginType: ""
          });
          
          // 变更修改认证方式示例
          function handleChangeLoginType () {
            idaasLogin.changeLoginType("qr") // v1.6 新增方法(如果想手动修改认证方式，请调用 此方法，传值请参考“参数说明”中的“loginType”)
          }`
    }
}
