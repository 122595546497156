<template>
    <div class="same-style-box">
        <div class="head-box">
            <div class="blue-box"></div>
            <div class="title-box">{{ title }}</div>
        </div>
        <div>
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SameStyleBox',
    props: {
        title: {
            type: String
        }
    }
}
</script>

<style lang="scss" scoped>
.same-style-box {
    background-color: #ffffff;
    .head-box {
        height: 48px;
        line-height: 48px;
        display: flex;
        align-items: center;
        .title-box {
            font-family: Source Han Sans CN;
            font-size: $fs13;
            letter-spacing: 0px;
            color: #000025;
        }
        .blue-box {
            width: 4px;
            height: 20px;
            background-color: #0284ff;
            margin-right: 12px;
        }
    }
}
</style>
