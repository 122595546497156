import moment from 'moment'
import _ from 'lodash'
// 将时间戳处理为字符串
export function handleDateToString(time, showHMS) {
    if (!time) {
        return '--'
    } else {
        let date
        if (time instanceof Date) {
            date = time
        } else {
            date = new Date(time)
        }
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()
        let hour = date.getHours()
        let minute = date.getMinutes()
        let second = date.getSeconds()
        month = month < 10 ? '0' + month : month
        day = day < 10 ? '0' + day : day
        hour = hour === 0 ? '00' : hour < 10 ? '0' + hour : hour
        minute = minute === 0 ? '00' : minute < 10 ? '0' + minute : minute
        second = second === 0 ? '00' : second < 10 ? '0' + second : second
        return year + '-' + month + '-' + day + (showHMS ? ' ' + hour + ':' + minute + ':' + second : '')
    }
}

// 格式化时间
export function formatDate(time, format = 'yyyy-MM-dd') {
    if (!time) {
        return '--'
    }
    let date
    if (time instanceof Date) {
        date = time
    } else {
        date = new Date(time)
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
        'q+': Math.floor((date.getMonth() + 3) / 3),
        S: date.getMilliseconds()
    }
    if (/(y+)/.test(format)) {
        format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (let k in o) {
        if (new RegExp('(' + k + ')').test(format)) {
            format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
        }
    }
    return format
}

// 保留n位小数位数
export function formatNum(str, n = 2) {
    const bit = Math.pow(10, n)
    const f = Math.round(str * bit) / bit
    return f.toString()
}

// js修改大小使用响应式
export function fontSize(res) {
    let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    let fontSize = (clientWidth / 20 / 750) * 20
    return res * fontSize
}

export function getLable(value, dist) {
    let res = dist.filter(item => item.value === value)[0]
    if (res) {
        return res.label
    } else {
        return ''
    }
}

export function getDeepLabel(value, list) {
    let name = ''
    if (Array.isArray(list)) {
        for (var item of list) {
            if (item.id == value || item.dataDomain == value || item.deptId == value) {
                name = item.deptName || item.name
                return name
            } else {
                if (item.children && item.children.length > 0) {
                    return getDeepLabel(value, item.children)
                }
            }
        }
    } else {
        return ''
    }
}

export function verifyTime(time) {
    return time >= 10 ? time : '0' + time
}

export function formatTime(dat, time) {
    let date = null
    if (dat) {
        date = new Date(dat)
    } else {
        return ''
    }
    let hour = date.getHours()
    let min = date.getMinutes()
    let sec = date.getSeconds()
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()
    if (time) {
        return verifyTime(hour) + ':' + verifyTime(min) + ':' + verifyTime(sec)
    } else {
        return verifyTime(year) + '-' + verifyTime(month) + '-' + verifyTime(day)
    }
}

export function dateInit() {
    let date = new Date().getTime()
    let oldDate = date - 3600 * 1000 * 24 * 90
    return [formatTime(oldDate), formatTime(date)]
}

//ant-design-vue 默认日期设置
export function defaultData() {
    let date = new Date().getTime()
    let oldDate = date - 3600 * 1000 * 24 * 90
    return [moment(formatTime(oldDate), 'YYYY-MM-DD'), moment(formatTime(date), 'YYYY-MM-DD')]
}

export function resolveTime(time) {
    let str = handleDateToString(time, true)
    return str.split(' ')[0] + '<br />' + str.split(' ')[1]
}

export function resolveTimeStream(time) {
    let str = handleDateToString(time, true)
    return str.split(' ')[0] + ' ' + str.split(' ')[1]
}
export function showStateLabel(value, options) {
    let res = _.find(options, { value })
    // console.log(res)
    return res ? res.label : ''
}

export function GetLable(value, dist) {
    if (!dist && typeof dist != 'undefined' && dist != 0) {
        return ''
    } else {
        let res = dist.filter(item => item.value === value)[0]
        return res ? res.label : ''
    }
}

//处理审批流信息
export function SortFlowHistory(data) {
    let res = []
    data.map(item => {
        res.push({
            handleMan: item.handleMan || 'System',
            handleInfo: item.handleInfo,
            status: item.handleInfo || '处理',
            createDate: item.createDate
        })
    })
    return res
}
// 下载
export function convertRes2Blob(response) {
    // 提取文件名
    const filename = response.headers['content-disposition'].match(/filename=(.*)/)[1]
    // 将二进制流转为blob
    const blob = new Blob([response.data], { type: 'application/octet-stream' })
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
        window.navigator.msSaveBlob(blob, decodeURI(filename))
    } else {
        // 创建新的URL并指向File对象或者Blob对象的地址
        const blobURL = window.URL.createObjectURL(blob)
        // 创建a标签，用于跳转至下载链接
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', decodeURI(filename))
        // 兼容：某些浏览器不支持HTML5的download属性
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank')
        }
        // 挂载a标签
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        // 释放blob URL地址
        window.URL.revokeObjectURL(blobURL)
    }
}
export function randomString(e) {
    // 随机字符串
    e = e || 12
    var t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
        a = t.length,
        n = ''
    for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a))
    return n
}
export function loadScript(url) {
    return new Promise(resolve => {
        var script = document.createElement('script')
        script.type = 'text/javascript'
        if (script.readyState) {
            //IE
            script.onreadystatechange = function() {
                if (script.readyState == 'loaded' || script.readyState == 'complete') {
                    script.onreadystatechange = null
                    // callback()
                    resolve()
                }
            }
        } else {
            //Others
            script.onload = function() {
                // callback()
                resolve()
            }
        }
        script.src = url
        document.getElementsByTagName('head')[0].appendChild(script)
    })
}
